import service from '@/utils/request2'

//获取考试列表
export function getExamList(params){
    return service({
        url:'/api/api/examination/list',
        method:'get',
        params
    })
}
export function getExamDetails(params){
    return service({
        url:'/api/api/examination/info',
        method:'get',
        params
    })
}
