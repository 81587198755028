<template>
    <div>
        <div class="ExamListText">
            考试列表
        </div>
        <div class="search mt20">
            <input type="text" placeholder="请输入搜索内容" v-model="search.title">
            <div class="fs20" @click="searchFn">
                搜索
            </div>
        </div>
        <div class="ExamListList mt40">
            <div v-for="(item, index) in list" :key="index">
                <div>
                    <img :src="item.cover_src" v-if="item.cover_src" width="100%" height="100%" alt="">
                    <img src="@/../public/image/examintion.png" v-else width="100%" height="100%" alt="">
                </div>
                <div>
                    <p class="fs18">{{ item.examination_title }}</p>
                    <p class="mt20"> 考试专业：{{ item.major_name }}</p>
                    <p>报名时间：{{ item.join_star_time }}</p>
                    <p>考试时间：{{ item.examination_star_time }}</p>
                    <p class="mt20">报名费用：{{ item.sign_price }}</p>
                </div>
                <div class="before">
                    <p class="fs24">{{ item.add_time2}}</p>
                    <p class="fs14 position">{{ item.add_time ? item.add_time.slice(0, 4) : '' }}</p>
                    <div class="start"
                        @click="$router.push('/occupationalRequirements/signupExamination?id=' + item.examination_id)">
                        查看详情
                    </div>
                </div>
            </div>
        </div>
        <PagePage :pageSize="search.page_size" :total="total" @change="pageChange" @changes="sizeChange"
            :current="search.page" />

    </div>
</template>

<script>
import PagePage from '@/components/page.vue'
import { getExamList } from '@/api/ExamList'
export default {
    components: { PagePage, },
    data() {
        return {
            search: {
                page_size: 3,
                page: 1,
                title: '',
                type: 1
            },
            total: 20,
            list: [

            ],

        }
    },
    mounted() {
        this.getExamListFn()
    },
    methods: {
        getExamListFn() {
            getExamList(this.search).then(res => {
                let reg = /-/g;
                if (res.code == 200) {
                    this.list = res.data.data
                    this.total = res.data.total
                    this.list.forEach(item => {
                        item.add_time2 = item.add_time ? item.add_time.slice(4, 10) : ''
                        item.add_time2 = item.add_time2.replace(reg, ' ')
                    })
                } else {
                    this.list = []
                }
            })
        },
        searchFn() {
            this.search.page = 1
            this.getExamListFn()
        },
        pageChange(val) {
            this.search.page = val
            this.getExamListFn()
        },
        sizeChange(val) {
            this.search.page = val
            this.getExamListFn()
        },
    },
}
</script>

<style  scoped>
.before::before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 40px;
    position: absolute;
    background: #9A9794;
    left: 20px;
}

.start {
    width: 110px;
    margin-top: auto;
    height: 38px;
    background: #CD0000;
    border-radius: 4px;
    font-size: 16px;
    color: white;
    text-align: center;
    line-height: 38px;
    cursor: pointer;
}



.ExamListList>div>div:nth-child(3) p {
    width: 100%;
}

.position {
    position: relative;
    top: -20px;
}

.ExamListList>div {
    display: flex;
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 20px;
    margin-top: 20px;
    box-sizing: border-box;
}

.ExamListList>div>div:nth-child(1) {
    width: 220px;
    height: 160px;
}

.ExamListList>div>div:nth-child(2) {
    color: #000000;
    margin-left: 20px;
    font-size: 14px;
}


.ExamListText {
    justify-content: center;
    color: #CD0000;
    display: flex;
    align-items: center;
    font-size: 22px;
}

.ExamListText::before {
    content: '';
    width: 80px;
    display: inline-block;
    margin-right: 20px;
    height: 2px;
    background: #CD0000;
}

.ExamListText::after {
    content: '';
    width: 80px;
    display: inline-block;
    height: 2px;
    margin-left: 20px;
    background: #CD0000;
}

input:focus {
    outline: none;
}

.search {
    display: flex;
    justify-content: space-between;
    width: 860px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
}

.search input {
    width: 100%;
    height: 100%;
    border: none;
    text-indent: 2em;
    color: #666666;

    font-size: 18px;
}

.search div {
    width: 120px;
    height: 48px;
    background: #CD0000;
    border-radius: 4px;
    color: #FFFFFF;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
}

.ExamListList>div>div:nth-child(3) {
    margin-left: auto;
    width: 110px;
    text-align: right;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}
</style>